




















































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapActions, mapMutations, MutationMethod } from 'vuex';

import { BASE_BLACK_COLOR } from '@/utils/constants';

@Component({
  methods: {
    ...mapActions({ forgotPassword: 'auth/forgotPassword' }),
    ...mapMutations({ loading: 'auth/loading' }),
  },
})
export default class ForgotPassword extends Vue {
  color = BASE_BLACK_COLOR;
  show = false;
  message = '';
  email = '';
  emailRules: Array<Function> = [];
  forgotPassword!: (email) => Promise<Object>;
  loading!: MutationMethod;

  onEmailFocus() {
    this.emailRules = [];
    this.show = false;
    this.message = '';
  }

  submit() {
    this.emailRules = [
      (v) => !!v || 'E-mail muss ausgefüllt sein',
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-z]{2,}))$/;
        return pattern.test(v) || 'E-mail hat ein falsches Format';
      },
    ];
    setTimeout(async () => {
      if ((this.$refs.form as any).validate()) {
        try {
          await this.loading(true);
          await this.forgotPassword({ email: this.email }).then((res: any) => {
            if (res.data?.error?.message) {
              this.loading(false);
              this.show = true;
              this.message = res.data.error.message;
            } else {
              this.loading(false);
              this.show = true;
              this.message = 'Bitte überprüfen Sie Ihre E-Mail';
            }
          });
        } catch (e: any) {
          this.loading(false);
          this.show = true;
          this.message = 'Bei der API Anfrage trat ein Fehler auf.';
          console.log(e.response);
        }
      }
    });
  }
}
